'use es6';

import { isHubSpotEmail } from './isHubSpotEmail';
(function () {
  const loc = window.mockLocation || window.location;
  if (!window.quickFetch) {
    console.warn('[PublicAuthCheck] quick-fetch required');
    return;
  }
  window.quickFetch.makeEarlyRequest('public-auth-check', {
    url: window.quickFetch.getApiUrl('/cookie-echo/v1/user'),
    type: 'GET',
    dataType: 'json',
    timeout: 2000,
    noPortalId: true,
    whenFinished: response => {
      if (isHubSpotEmail(response.email) && (!loc.search.includes('_redirect=true') || loc.search.includes('_healthCheck=true'))) {
        return;
      }
      const isQa = /hubspotqa/.test(loc.hostname);
      // eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils
      const appDomain = isQa ? 'app.hubspotqa.com' : 'app.hubspot.com';
      const redirectPath = loc.pathname.replace(/^.*\/marketplace/, '');
      const redirectUrl = `https://${appDomain}/l/marketplace${redirectPath}${loc.search}`;
      loc.replace(redirectUrl);
    }
  });
})();